import http from '../utils/request'

const serveUrl: any = {
    orderList: '/conversion/logistics/status',
    isbindAddress: '/conversion/isbind/address',
    updateAddress: '/conversion/update/address',
    addAddress:'/conversion/add/address'

}

// 查询订单
export const getOrderList = (data: Record<string,any>) => {
    return http.request({
        url: serveUrl.orderList,
        method: 'POST',
        data
    })
}

// 用户是否绑定地址
export const isbindAddress = (user_id: number) => {
    return http.request({
        url: serveUrl.isbindAddress,
        method: 'GET',
        params: {
            user_id
        }
    })
}

interface AddRessDto<T=string, K=number> {
    id?: number | any
    user_id: K;
    name: T;
    mobile: T
    province: T
    city: T
    district: T
    detail: T
}
// 添加地址
export const addAddressApi = (data: AddRessDto) => {
    return http.request({
        url: serveUrl.addAddress,
        method: 'POST',
        data
    })
}
// 修改地址
export const updateAddressApi = (data: AddRessDto) => {
    return http.request({
        url: serveUrl.updateAddress,
        method: 'POST',
        data
    })
}